<template>
  <component :is="branchData === undefined || branchData === null ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="branchData === undefined || branchData === null"
    >
      <h4 class="alert-heading">
        Error fetching branch data
      </h4>
      <div class="alert-body">
        No user found with this branch id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-branches-list'}"
        >
          Branch List
        </b-link>
        for other branches.
      </div>
    </b-alert>

    <b-tabs
      v-if="branchData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Branch Information</span>
        </template>
        <user-edit-tab-account
          :branch-data="branchData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import UserEditTabAccount from './BranchEditTabAccount.vue'
import UserEditTabInformation from './BranchEditTabInformation.vue'
import UserEditTabSocial from './BranchEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  setup() {
      const branchData = JSON.parse(localStorage.getItem('branchEdit'))
      console.log('mantap')
      console.log(branchData)
      return {
        branchData,
      }
  },
}
</script>

<style>

</style>
