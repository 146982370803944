<template>
  <div>

    <validation-observer
          ref="editBranchForm"
    >
    <!-- User Info: Input Fields -->
    <b-form
    @submit.prevent="editBranch"
    >
      <b-row>
        <!-- Field: Kota -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Kota"
            rules="required"
          >
          <b-form-group
            label="Kota"
            label-for="Kota"
          >
            <b-form-input
              id="Kota"
              v-model="Kota"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-col>

        <!-- Field: KodeUnitKerja -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Kode Unit Kerja"
            label-for="KodeUnitKerja"
          >
            <b-form-input
              id="KodeUnitKerja"
              v-model="KodeUnitKerja"
            />
          </b-form-group>
        </b-col>

        <!-- Field: KodeArea -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Kode Area"
            label-for="KodeArea"
          >
            <b-form-input
              id="KodeArea"
              v-model="KodeArea"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Phone -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Deskripsi"
            label-for="Deskripsi"
          >
            <b-form-input
              id="Deskripsi"
              v-model="Deskripsi"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Alamat1 -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Alamat1"
            label-for="Alamat1"
          >
            <b-form-input
              id="Alamat1"
              v-model="Alamat1"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Alamat2"
            label-for="Alamat2"
          >
            <b-form-input
              id="Alamat2"
              v-model="Alamat2"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Telp1 -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Telp1"
            label-for="Telp1"
          >
            <b-form-input
              id="Telp1"
              v-model="Telp1"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Telp2 -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Telp2"
            label-for="Telp2"
          >
            <b-form-input
              id="Telp2"
              v-model="Telp2"
            />
          </b-form-group>
        </b-col>
        <!-- Field: EmailPembayaran -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email Pembayaran"
            label-for="EmailPembayaran"
          >
            <b-form-input
              id="EmailPembayaran"
              v-model="EmailPembayaran"
            />
          </b-form-group>
        </b-col>
        <!-- Field: EmailDeposit -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email Deposit"
            label-for="EmailDeposit"
          >
            <b-form-input
              id="EmailDeposit"
              v-model="EmailDeposit"
            />
          </b-form-group>
        </b-col>
      </b-row>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="submit"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Save Changes
    </b-button>
    </b-form>
    </validation-observer>

    <!-- <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button> -->
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getToken } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const branchToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${branchToken}`,
}
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    branchData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      props,
    }
  },
  data() {
    return {
      required,
      BranchID: this.branchData.BranchID,
      Kota: this.branchData.Kota,
      KodeUnitKerja: this.branchData.KodeUnitKerja,
      KodeArea: this.branchData.KodeArea,
      Deskripsi: this.branchData.Deskripsi,
      Alamat1: this.branchData.Alamat1,
      Alamat2: this.branchData.Alamat2,
      Telp1: this.branchData.Telp1,
      Telp2: this.branchData.Telp2,
      EmailPembayaran: this.branchData.EmailPembayaran,
      EmailDeposit: this.branchData.EmailDeposit,
    }
  },
  mounted() {
  },
  methods: {
    getBranches() {
        axios
            .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
            .then(response => {
              const branchesJoin = response.data.Payload.map(elem => (
                { label: elem.Kota, value: elem.BranchID }
              ))
              this.branches = branchesJoin
            })
            .catch(err => {
                console.log(err)
            })
    },
    editBranch() {
      console.log('woy')
      this.$refs.editBranchForm.validate().then(success => {
        const payload = {
            BranchID: this.BranchID,
            Kota: this.Kota,
            KodeUnitKerja: this.KodeUnitKerja,
            Deskripsi: this.Deskripsi,
            Alamat1: this.Alamat1,
            Alamat2: this.Alamat2,
            Telp1: this.Telp1,
            Telp2: this.Telp2,
            KodeArea: this.KodeArea,
            EmailPembayaran: this.EmailPembayaran,
            EmailDeposit: this.EmailDeposit,
          }
          console.log(payload)
        if (success) {
          axios
          .post('https://api.geolims.com/user_service/editbranch', payload, { headers })
          .then(response => {
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              if (response.data.Status === 1) {
                this.$root.$emit('refreshTable', 'refreshTable')
                // this.$parent.refBranchListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been saved!',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              }
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
